import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './Consumer.module.css';
import Testimonial from './Testimonial';
import Footer from './Footer';
import HomeHeader from './HomeHeader';
import InstrumentedCtaButton from './InstrumentedCtaButton';
import CheckList from './CheckList';
import Video from './Video';
import AnimatedTitleKeyword from './AnimatedTitleKeyword';
import { sendPageHitEvent } from '../lib/frontend/pixel';

const Consumer: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    sendPageHitEvent({ category: 'consumer' });
  }, []);

  const onClickGetStarted = useCallback(() => {
    router.push('/dashboard');
  }, [router]);

  return (
    <Container fluid>
      <Head>
        <title>Learn SQL Analytics - supercooldata</title>
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.supercooldata.com/" />
        <meta property="og:title" content="Learn SQL analytics" />
        <meta
          property="og:description"
          content="The best platform to learn how to solve real world problems with SQL."
        />
        <meta
          property="og:image"
          content="https://www.supercooldata.com/preview1.gif"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:domain" content="www.supercooldata.com" />
        <meta name="twitter:title" content="Learn SQL analytics" />
        <meta
          name="twitter:description"
          content="The best platform to learn how to solve real world problems with SQL."
        />
        <meta
          name="twitter:image"
          content="https://www.supercooldata.com/preview1.gif"
        />
        <meta name="twitter:url" content="https://www.supercooldata.com/" />
        <meta name="twitter:label1" content="Ready to be an analytics pro?" />
        <meta name="twitter:data1" content="Start a free trial now" />
      </Head>
      <HomeHeader page="consumer" onClickCta={onClickGetStarted} />
      <Row>
        <Col>
          <Container>
            <Row>
              <Col className={styles.title}>
                <span className="d-block d-md-inline">Up your</span>{' '}
                <span className={styles.titleHighlight}>
                  <AnimatedTitleKeyword />
                </span>
                <br />
                analytics game
              </Col>
            </Row>
            <Row>
              <Col className={styles.subtitle}>
                <span className="d-block d-md-inline">
                  Learn how to solve real
                </span>{' '}
                world problems with SQL
              </Col>
            </Row>
            <Row>
              <Col className={styles.firstCta}>
                <InstrumentedCtaButton
                  text="Free trial"
                  eventLabel="clickConsumerLandingPageCtaButton"
                  onClick={onClickGetStarted}
                />
                <div className={styles.noCreditCardNeeded}>
                  No credit card needed
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={styles.preview1}>
                <div>
                  <Video
                    gif="preview1.gif"
                    mp4="preview1.mp4"
                    width={1024}
                    height={576}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Testimonial person="karla" first={true} />
      <Testimonial person="dejan" last={true} />
      <Row>
        <Col>
          <Container>
            <Row>
              <Col className={styles.subheading}>Why should you learn SQL?</Col>
            </Row>
            <Row>
              <Col className={styles.whyLearnSqlCta}>
                <InstrumentedCtaButton
                  text="View Tutorials"
                  eventLabel="clickConsumerLandingPageCtaButton"
                  onClick={onClickGetStarted}
                />
              </Col>
            </Row>
            <Row className={styles.valueProps}>
              <Col md={4} sm={6}>
                <div>
                  <div>
                    <img src="value-prop-career.png" />
                  </div>
                </div>
                <h3>Career</h3>
                <p>Develop analytical skills</p>
              </Col>
              <Col md={4} sm={6}>
                <div>
                  <div>
                    <img src="value-prop-self-reliance.png" />
                  </div>
                </div>
                <h3>Self-Reliance</h3>
                <p>Skip the data queue</p>
              </Col>
              <Col md={4} sm={6}>
                <div>
                  <div>
                    <img src="value-prop-no-tool-limits.png" />
                  </div>
                </div>
                <h3>No Tool Limits</h3>
                <p>Answer any data question</p>
              </Col>
              <Col md={4} sm={6}>
                <div>
                  <div>
                    <img src="value-prop-save-time.png" />
                  </div>
                </div>
                <h3>Save Time</h3>
                <p>Automate tedius reporting</p>
              </Col>
              <Col md={4} sm={6}>
                <div>
                  <div>
                    <img src="value-prop-big-data.png" />
                  </div>
                </div>
                <h3>Big Data</h3>
                <p>Analyse billions of records</p>
              </Col>
              <Col md={4} sm={6}>
                <div>
                  <div>
                    <img src="value-prop-hidden-data.png" />
                  </div>
                </div>
                <h3>Hidden Data</h3>
                <p>Find hidden datasets</p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Testimonial person="hijab" first={true} />
      <Testimonial person="brad" last={true} />
      <Row>
        <Col>
          <Container>
            <Row>
              <Col
                className={styles.easiestWayToLearnHeading}
                md={{ span: 8, offset: 2 }}
              >
                The fastest way to master SQL analytics
              </Col>
            </Row>
            <Row>
              <Col className={styles.easiestWayToLearnCta}>
                <InstrumentedCtaButton
                  text="Start Now for Free"
                  eventLabel="clickConsumerLandingPageCtaButton"
                  onClick={onClickGetStarted}
                />
              </Col>
            </Row>
            <Row className={styles.easiestWayToLearnFeatures}>
              <Col className={styles.easiestWayToLearnFeaturesLeft} md={4}>
                <Row className={styles.easiestWayToLearnFeature}>
                  <Col xs={2} className={styles.easiestWayToLearnFeatureIcon}>
                    <img src="ribbon.png" />
                  </Col>
                  <Col>
                    <div className={styles.easiestWayToLearnFeatureHeader}>
                      Stay motivated
                    </div>
                    <div className={styles.easiestWayToLearnFeatureDescription}>
                      A fun learning experience with rewards to keep you
                      motivated.
                    </div>
                  </Col>
                </Row>
                <Row className={styles.easiestWayToLearnFeature}>
                  <Col xs={2} className={styles.easiestWayToLearnFeatureIcon}>
                    <img src="puzzle.png" />
                  </Col>
                  <Col>
                    <div className={styles.easiestWayToLearnFeatureHeader}>
                      Real world examples
                    </div>
                    <div className={styles.easiestWayToLearnFeatureDescription}>
                      Learn to solve actual problems you will have at work.
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className={styles.easiestWayToLearnFeaturesComputer} md={4}>
                <div>
                  <img src="computer.png" />
                </div>
              </Col>
              <Col className={styles.easiestWayToLearnFeaturesRight} md={4}>
                <Row className={styles.easiestWayToLearnFeature}>
                  <Col xs={2} className={styles.easiestWayToLearnFeatureIcon}>
                    <img src="rocket.png" />
                  </Col>
                  <Col>
                    <div className={styles.easiestWayToLearnFeatureHeader}>
                      Improve quickly
                    </div>
                    <div className={styles.easiestWayToLearnFeatureDescription}>
                      A range of learning formats designed to learn as fast as
                      possible.
                    </div>
                  </Col>
                </Row>
                <Row className={styles.easiestWayToLearnFeature}>
                  <Col xs={2} className={styles.easiestWayToLearnFeatureIcon}>
                    <img src="fire.png" />
                  </Col>
                  <Col>
                    <div className={styles.easiestWayToLearnFeatureHeader}>
                      Adaptive difficulty
                    </div>
                    <div className={styles.easiestWayToLearnFeatureDescription}>
                      The rate of progress is never too easy or too hard.
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className={styles.preview2}>
                <div>
                  <Video
                    gif="preview2.gif"
                    mp4="preview2.mp4"
                    width={1024}
                    height={576}
                    topPadding={18}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Testimonial person="tom" first={true} />
      <Testimonial person="jose" last={true} />
      <Row>
        <Col>
          <Container>
            <Row className={`${styles.relevantDatasets}`}>
              <Col
                className={`order-2 order-md-1 ${styles.relevantDatasetsLogos}`}
                md={6}
              >
                <div className={styles.previewImage}>
                  <img src="delight-your-users.png" />
                </div>
              </Col>
              <Col
                className={`order-1 order-md-2 mb-5 mb-md-0 text-center text-md-left ${styles.relevantDatasetsContent}`}
                md={6}
              >
                <div className={styles.relevantDatasetsHeading}>
                  Product Analytics
                </div>
                <div className={styles.relevantDatasetsSubheading}>
                  Delight your users
                </div>
                <div className={styles.relevantDatasetsBody}>
                  <CheckList>
                    <CheckList.Item>User Retention Analysis</CheckList.Item>
                    <CheckList.Item>Power User Curves</CheckList.Item>
                    <CheckList.Item>User Growth Accounting</CheckList.Item>
                    <CheckList.Item>Build User Funnels</CheckList.Item>
                    <CheckList.Item>Analyse User Flow</CheckList.Item>
                  </CheckList>
                </div>
                <div className={styles.relevantDatasetsCta}>
                  <InstrumentedCtaButton
                    text="Get Started"
                    eventLabel="clickConsumerLandingPageCtaButton"
                    onClick={onClickGetStarted}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.learningFormat}>
              <Col
                className={`mb-5 mb-md-0 text-center text-md-left ${styles.learningFormatContent}`}
                md={6}
              >
                <div className={styles.learningFormatHeading}>
                  Marketing Analytics
                </div>
                <div className={styles.learningFormatSubheading}>
                  Optimise your channels
                </div>
                <div className={styles.learningFormatBody}>
                  <CheckList>
                    <CheckList.Item>Channel Attribution</CheckList.Item>
                    <CheckList.Item>Channel Path Analysis</CheckList.Item>
                    <CheckList.Item>User Segmentation</CheckList.Item>
                  </CheckList>
                </div>
                <div className={styles.learningFormatCta}>
                  <InstrumentedCtaButton
                    text="View Tutorials"
                    eventLabel="clickConsumerLandingPageCtaButton"
                    onClick={onClickGetStarted}
                  />
                </div>
              </Col>
              <Col className={styles.learningFormatLogos} md={6}>
                <div className={styles.previewImage}>
                  <img src="optimise-your-channels.png" />
                </div>
              </Col>
            </Row>
            <Row className={styles.chooseDatabase}>
              <Col
                className={`order-2 order-md-1 ${styles.chooseDatabaseLogos}`}
                md={6}
              >
                <div className={styles.previewImage}>
                  <img src="climb-the-rankings.png" />
                </div>
              </Col>
              <Col
                className={`order-1 order-md-2 mb-5 mb-md-0 text-center text-md-left ${styles.chooseDatabaseContent}`}
                md={6}
              >
                <div className={styles.chooseDatabaseHeading}>
                  SEO Analytics
                </div>
                <div className={styles.chooseDatabaseSubheading}>
                  Climb the rankings
                </div>
                <div className={styles.chooseDatabaseBody}>
                  <CheckList>
                    <CheckList.Item>Crawled Ranking Analysis</CheckList.Item>
                    <CheckList.Item>Server Log Analysis</CheckList.Item>
                    <CheckList.Item>Keyword Analysis</CheckList.Item>
                  </CheckList>
                </div>
                <div className={styles.chooseDatabaseCta}>
                  <InstrumentedCtaButton
                    text="Free Trial"
                    eventLabel="clickConsumerLandingPageCtaButton"
                    onClick={onClickGetStarted}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Testimonial person="clinton" first={true} />
      <Testimonial person="jeff" last={true} />
      <Row>
        <Col>
          <Container>
            <Row className={styles.interactiveEditor}>
              <Col
                className={`mb-5 mb-md-0 text-center text-md-left ${styles.interactiveEditorLeft}`}
                md={{ span: 5, offset: 1 }}
              >
                <div className={styles.interactiveEditorHeading}>
                  Interactive SQL Editor
                </div>
                <div className={styles.interactiveEditorSubheading}>
                  Interactive editor to learn data analysis
                </div>
                <div className={styles.interactiveEditorCta}>
                  <InstrumentedCtaButton
                    text="View Tutorials"
                    eventLabel="clickConsumerLandingPageCtaButton"
                    onClick={onClickGetStarted}
                  />
                </div>
              </Col>
              <Col
                className={`text-center text-md-left ${styles.interactiveEditorBody}`}
                md={5}
              >
                <div>
                  A simple SQL editor that gradually introduces you to writing
                  your own queries.
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={styles.preview3}>
                <div>
                  <Video
                    gif="preview3.gif"
                    mp4="preview3.mp4"
                    width={1024}
                    height={576}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Testimonial person="rachel" first={true} />
      <Testimonial person="kristjan" last={true} />
      <Row className={styles.finalCtaContainer}>
        <Col>
          <Container>
            <Row>
              <Col className={styles.finalCtaHeading1}>
                Ready to be an analytics pro?
              </Col>
            </Row>
            <Row>
              <Col className={styles.finalCtaButton}>
                <InstrumentedCtaButton
                  text="Start Now"
                  variant="dark-bg"
                  eventLabel="clickConsumerLandingPageCtaButton"
                  onClick={onClickGetStarted}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default Consumer;
