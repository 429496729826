import React, { useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './Testimonial.module.css';
import InstrumentedLink from './InstrumentedLink';
import { CATEGORIES } from '../lib/universal/types';
import { TestimonialKey, testimonials } from '../lib/frontend/testimonials';

const Testimonial: React.FC<{
  person: TestimonialKey;
  first?: boolean;
  last?: boolean;
}> = ({ person, first, last }) => {
  const {
    name,
    title,
    company,
    message,
    linkedinUrl,
    imageLinkUrl,
  } = useMemo(() => testimonials[person], [person]);
  const imageUrl = `testimonial-icons/${person}.jpg`;
  return (
    <Row
      className={`${styles.testimonialContainer} ${first ? styles.first : ''} ${
        last ? styles.last : ''
      }`}
    >
      <Col md={{ span: 6, offset: 3 }}>
        <Container>
          <Row>
            <Col>
              <img src="quote.png" />
            </Col>
          </Row>
          <Row>
            <Col className={styles.testimonialMessage}>
              <div>{message}</div>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="auto" className={styles.testimonialAvatar}>
              <div>
                {imageLinkUrl !== undefined ? (
                  <InstrumentedLink
                    href={imageLinkUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                    category={CATEGORIES.LANDING}
                    label={`testimonial_${person}_personal_website`}
                  >
                    <div className={styles.testimonialAvatarBorder}>
                      <img src={imageUrl} />
                    </div>
                  </InstrumentedLink>
                ) : (
                  <div className={styles.testimonialAvatarBorder}>
                    <img src={imageUrl} />
                  </div>
                )}
              </div>
            </Col>
            <Col className={styles.testimonialPersonDetails}>
              <div
                className={`${styles.testimonialName} text-center text-md-left`}
              >
                <InstrumentedLink
                  href={linkedinUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  category={CATEGORIES.LANDING}
                  label={`testimonial_${person}_linked_in_profile`}
                >
                  {name}
                </InstrumentedLink>
              </div>
              <div
                className={`${styles.testimonialTitle} text-center text-md-left`}
              >
                <span>{title}</span>
                <span className="d-none d-md-inline">, </span>
                <span className="d-block d-md-inline">{company}</span>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default Testimonial;
