import React from 'react';

import styles from './CheckList.module.css';

/*
// Usage:

<CheckList>
  <CheckList.Item>First</CheckListItem>
  <CheckList.Item>Second</CheckListItem>
  <CheckList.Item>Third</CheckListItem>
  <CheckList.Item>Fourth</CheckListItem>
</CheckList>
*/

type CheckListItemType = React.FC;
type CheckListType = React.FC<{ align?: 'center' | 'left' }> & {
  Item: CheckListItemType;
};

const CheckList: CheckListType = function ({ align = 'center', children }) {
  return (
    <div
      className={`${styles.list} ${
        align === 'center'
          ? styles.alignCenter
          : align === 'left'
          ? styles.alignLeft
          : ''
      }`}
    >
      {children}
    </div>
  );
};

const CheckListItem: CheckListItemType = ({ children }) => (
  <div className={styles.item}>
    <div className={styles.tick}>
      <img src="tick.png" />
    </div>
    <div className={styles.itemContent}>{children}</div>
  </div>
);

CheckList.Item = CheckListItem;

export default CheckList;
