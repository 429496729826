const testimonialsRaw = {
  karla: {
    name: 'Karla Sumera',
    title: 'Senior SEO Manager',
    company: 'Canva',
    linkedinUrl: 'https://www.linkedin.com/in/karlasumera',
    message:
      "The SQL course will transform the way you work with large datasets, especially if you're an in-house SEO working on enterprise level sites. Having no prior experience with SQL before the course, I can now get straight to the answers and insights that I need without having to tediously export multiple spreadsheets and stitch data back together. There's been so many different applications in my role where SQL skills have been incredibly valuable.",
  },

  dejan: {
    name: 'Dejan Mladenovski',
    title: 'SEO Engineer',
    company: 'Localsearch',
    linkedinUrl: 'https://www.linkedin.com/in/dejan-m/',
    message:
      'supercooldata is the best resource I found as a technical SEO to learn BigQuery that is specific for SEO, the uses cases and examples are relevant and it is great for learning to manipulate Google Search Console data with SQL.',
  },

  hijab: {
    name: 'Hijab Alavi',
    title: 'Digital Data Quality Officer',
    company: 'Domain Group',
    linkedinUrl: 'https://www.linkedin.com/in/hijab-alavi',
    message:
      'The course has a great balance between theory and practical. It allowed me to learn the concepts and then practise them. It was very flexible and tailored based on my needs and provided data sources and examples relevant to them. The real life examples helped me to learn SQL better and how to apply it according to my work needs.',
  },

  brad: {
    name: 'Brad Forster',
    title: 'Head of SEO',
    company: 'Domain Group',
    linkedinUrl: 'https://www.linkedin.com/in/bradforster',
    message:
      "The data analysis with SQL course equipped me with the skills to combine datasets and answer real business questions that traditional tools couldn't. It totally changed the way I make data driven decisions. I can highly recommend it.",
    imageLinkUrl: 'https://bradforster.org/',
  },

  tom: {
    name: 'Tom Donohoe',
    title: 'Global SEO Manager',
    company: 'Finder',
    linkedinUrl: 'https://www.linkedin.com/in/tomdonohoe',
    message:
      "The SQL for data analysis course changed my workflow from basic spreadsheet analysis to powerful big data analysis. The real world examples and datasets let me see the value of SQL quickly and encouraged me to learn. I can't imagine doing my job without SQL in my skillset.",
  },

  jose: {
    name: 'José Luis Fausto Martinez',
    title: 'SEO Strategist',
    company: 'Portent',
    linkedinUrl: 'https://www.linkedin.com/in/jlfausto',
    message:
      "The supercooldata SQL course is amazing. I really enjoyed doing it and it's super fun. I had no idea about SQL and this has helped me learn much quicker.",
  },

  clinton: {
    name: 'Clinton McNabb',
    title: 'Data Scientist (Former Marketer)',
    company: 'Domain Group',
    linkedinUrl: 'https://www.linkedin.com/in/clinton-mcnabb-03ba224a',
    message:
      "The SQL courses were a game-changer for me. In the past, I was heavily reliant on spreadsheets and time-consuming manual processes, but as the amount of data exploded in our company, my lack of SQL knowledge was becoming a real problem. Upskilling in SQL helped me do my job better, and get access to the data I needed to without having to wait days or weeks for someone else to query the data. In-fact, the program put me on a path to a new career, from Marketer to Data Scientist. The courses are well-paced for non-technical users and the heavy focus on real-life business problems helped me stick with it. It does a fantastic job of explaining complex concepts. I've seen first-hand how these programs can transform an organisation.",
  },

  jeff: {
    name: 'Jeff Swanson',
    title: 'Group Director (Digital Marketing)',
    company: 'Performics',
    linkedinUrl: 'https://www.linkedin.com/in/jeffgswanson',
    message:
      "I'm really loving it, it's great that you learn to solve the same problem in a few different ways - it helps make it more memorable. The different question formats really reinforce the SQL concepts.",
  },

  rachel: {
    name: 'Rachel Anderson',
    title: 'Technical SEO Analyst',
    company: 'Deep Crawl',
    linkedinUrl: 'https://www.linkedin.com/in/rachel-anderson-a5b0a460',
    message:
      'I am absolutely loving the supercooldata SQL course so far. I really like that I can do a small amount each day and learn at my own pace.',
  },

  kristjan: {
    name: 'Kristjan Smarason',
    title: 'Senior Marketing Analyst',
    company: 'Zip Co',
    linkedinUrl: 'https://www.linkedin.com/in/kristjansmarason',
    message:
      'Before taking the data analytics with SQL course I was doing a lot of manual reporting with Excel which I can now automate by scheduling SQL scripts. This gave me a lot more time to answer more business questions rather than being bogged down with day to day reporting. I particularly liked the real Google Analytics examples in the course and how I could join the user event tables back to company specific tables which meant that I could answer questions that nobody else had answers for.',
  },
};

export type TestimonialData = {
  name: string;
  title: string;
  company: string;
  message: string;
  linkedinUrl: string;
  imageLinkUrl?: string;
};
export type TestimonialKey = keyof typeof testimonialsRaw;
export type TestimonialsData = Record<TestimonialKey, TestimonialData>;
export const testimonials: TestimonialsData = testimonialsRaw;
